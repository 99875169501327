import React from 'react';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AboutSection,AboutLeft,
    AboutRight,AboutBtn,AboutRow} from './about.style'
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const About = () => {
    const JSONData  = useStaticQuery(graphql`{
  interior2Json {
    About {
      DelayConstant
      AboutLeftImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      AboutHeading
      AboutDesc
      AboutBtn
    }
  }
}
`);

    return (
        <AboutSection id="aboutContainer">
            <Container>
                <AboutRow>
                    <Col md="6">
                        <Fade left delay={1*JSONData.interior2Json.About.DelayConstant}>
                            <AboutLeft>
                                <GatsbyImage
                                    image={JSONData.interior2Json.About.AboutLeftImage.childImageSharp.gatsbyImageData}
                                    className="AboutLeftImage"
                                    alt="" />
                            </AboutLeft>
                        </Fade>
                    </Col>
                    <Col md="6">
                        <AboutRight>
                            <Fade right delay={1*JSONData.interior2Json.About.DelayConstant}>
                                <h3>{JSONData.interior2Json.About.AboutHeading}</h3>
                            </Fade>
                            <Fade right delay={2*JSONData.interior2Json.About.DelayConstant}>
                                <p>{JSONData.interior2Json.About.AboutDesc}</p>
                            </Fade>
                            <Fade right delay={3*JSONData.interior2Json.About.DelayConstant}>
                                <AboutBtn href="#">{JSONData.interior2Json.About.AboutBtn}</AboutBtn>
                            </Fade>
                        </AboutRight>
                    </Col>
                </AboutRow>
            </Container>
        </AboutSection>
    );
}

export default About;

import React,{Component} from 'react';
import {BannerSection,
    BannerWrapper,
    BannerContents,
    BannerContent,
    BannerContentRight,
    SliderCol,
    BannerBtn,RightArrowIcon,LeftArrowIcon,
    NextPrev
} from './banner.style'
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Banner extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){

        var settings = {
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <BannerSection id="homeContainer">
                <BannerWrapper>
                    <BannerContents>
                        <BannerContentRight>
                            <Fade bottom delay={1*this.props.interior2Json.Banner.DelayConstant}>
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    <SliderCol>
                                        <GatsbyImage
                                        image={this.props.interior2Json.Banner.BannerImage1.childImageSharp.gatsbyImageData}
                                        className="BannerImage"
                                        imgStyle={{
                                            marginBottom:"0px"
                                        }}
                                        alt="" />

                                        <BannerContent>
                                            <Fade bottom delay={1*this.props.interior2Json.Banner.DelayConstant}>
                                                <div>
                                                    <h2>{this.props.interior2Json.Banner.BannerH3}</h2>
                                                    <h3>{this.props.interior2Json.Banner.BannerH4}</h3>
                                                    <p>{this.props.interior2Json.Banner.BannerPara}</p>
                                                    <BannerBtn href="#">{this.props.interior2Json.Banner.BannerBtn}</BannerBtn>
                                                </div>
                                            </Fade>
                                        </BannerContent>
                                    </SliderCol>
                                    <SliderCol>
                                        <GatsbyImage
                                            image={this.props.interior2Json.Banner.BannerImage2.childImageSharp.gatsbyImageData}
                                            className="BannerImage"
                                            imgStyle={{
                                                marginBottom:"0px"
                                            }}
                                            alt="" />

                                        <BannerContent>
                                            <Fade bottom delay={1*this.props.interior2Json.Banner.DelayConstant}>
                                                <div>
                                                    <h2>{this.props.interior2Json.Banner.BannerH3}</h2>
                                                    <h3>{this.props.interior2Json.Banner.BannerH4}</h3>
                                                    <p>{this.props.interior2Json.Banner.BannerPara}</p>
                                                    <BannerBtn href="#">{this.props.interior2Json.Banner.BannerBtn}</BannerBtn>
                                                </div>
                                            </Fade>
                                        </BannerContent>
                                    </SliderCol>
                                    
                                    <SliderCol>
                                        <GatsbyImage
                                            image={this.props.interior2Json.Banner.BannerImage3.childImageSharp.gatsbyImageData}
                                            className="BannerImage"
                                            imgStyle={{
                                                marginBottom:"0px"
                                            }}
                                            alt="" />
                                            <BannerContent>
                                            <Fade bottom delay={1*this.props.interior2Json.Banner.DelayConstant}>
                                                <div>
                                                    <h2>{this.props.interior2Json.Banner.BannerH3}</h2>
                                                    <h3>{this.props.interior2Json.Banner.BannerH4}</h3>
                                                    <p>{this.props.interior2Json.Banner.BannerPara}</p>
                                                    <BannerBtn href="#">{this.props.interior2Json.Banner.BannerBtn}</BannerBtn>
                                                </div>
                                            </Fade>
                                        </BannerContent>
                                    </SliderCol>
                                </Slider>
                            </Fade>
                            <NextPrev>
                                <LeftArrowIcon onClick={this.previous} src={this.props.interior2Json.Banner.PrevImg} alt=""/>
                                <RightArrowIcon onClick={this.next} src={this.props.interior2Json.Banner.NextImg} alt=""/>
                            </NextPrev>

                        </BannerContentRight>
                    </BannerContents>
                </BannerWrapper>
            </BannerSection>
        );
    }
}


const BannerPageSection = () => (
    <StaticQuery
        query={graphql`{
  interior2Json {
    Banner {
      BannerBtn
      BannerH3
      BannerH4
      BannerImage1 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      BannerImage2 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      BannerImage3 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      DelayConstant
      BannerPara
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Banner interior2Json={data.interior2Json}/>
        )}
    />
)
export default BannerPageSection

import React,{Component} from 'react';
import {FeaturesSliderSection,SliderCol,SliderHover,SliderColWrapper,
    SliderWrapperHeading,NextPrev,PrevImg,NextImg,ImgButton} from './featuresslider.style'
import Slider from "react-slick";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from "react-reveal/Fade";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FeaturesSlider extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){
        const settings = {
            dots: false,
            arrows:true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        dots: false,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        dots: false,
                        slidesToScroll: 1,
                        infinite: true,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        dots: false,
                        infinite: true,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <FeaturesSliderSection id="featuresContainer">
                <Fade bottom delay={1*this.props.interior2Json.FeaturesSlider.DelayConstant}>
                    <SliderWrapperHeading>{this.props.interior2Json.FeaturesSlider.SliderWrapperHeading}</SliderWrapperHeading>
                </Fade>
                <Container>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        this.props.interior2Json.FeaturesSlider.SliderColWrapper.map((item,idx) => {
                        return (
                            <Fade bottom delay={(idx+1)*this.props.interior2Json.FeaturesSlider.DelayConstant}>
                                <SliderColWrapper>
                                    <SliderCol>
                                        <GatsbyImage
                                            image={item.SliderImg.childImageSharp.gatsbyImageData}
                                            className="SliderImg"
                                            alt="" />
                                        <SliderHover>
                                            <h4>
                                            {item.SliderHoverText}
                                            </h4>
                                        </SliderHover>
                                    </SliderCol>
                                </SliderColWrapper>
                            </Fade>
                        );
                        })
                    }
                    </Slider>
                </Container>
                <NextPrev>
                    <ImgButton onClick={this.previous} aria-label="Prev Button">
                        <PrevImg
                            src={this.props.interior2Json.FeaturesSlider.PrevImg}
                            alt=""
                        />
                    </ImgButton>
                    <ImgButton onClick={this.next} aria-label="Next Button">
                        <NextImg
                            src={this.props.interior2Json.FeaturesSlider.NextImg}
                            alt=""
                        />
                    </ImgButton>
                </NextPrev>
            </FeaturesSliderSection>
        );
    }
}
const FeaturesSliderPageSection =() => (
    <StaticQuery
        query={graphql`{
  interior2Json {
    FeaturesSlider {
      DelayConstant
      SliderWrapperHeading
      SliderColWrapper {
        SliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        SliderHoverText
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <FeaturesSlider interior2Json={data.interior2Json}/>
        )}
    />
  )
  export default FeaturesSliderPageSection;

import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {KeyServicesSection,KeyServicesRight,
    KeyServicesCol1,KeyServicesCol2,
    KeyServicesCustomContainer,
    KeyServicesRightInnerRow} from './keyservices.style'
import {SectionHeading} from '../Common/common.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";

const KeyServices = () => {
    const JSONData  = useStaticQuery(graphql`{
  interior2Json {
    KeyServices {
      DelayConstant
      SectionHeading
      KeyServicesLeftImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      KeyServicesCustomContainer {
        KeyServicesIcon
        KeyServicesHeading
        KeyServicesDesc
      }
    }
  }
}
`);
    return (
        <KeyServicesSection id="servicesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.interior2Json.KeyServices.DelayConstant}>
                            <SectionHeading>{JSONData.interior2Json.KeyServices.SectionHeading}</SectionHeading>
                        </Fade>
                    </Col>
                </Row>

                <Fade bottom delay={1*JSONData.interior2Json.KeyServices.DelayConstant}>
                    <KeyServicesCustomContainer>
                        <KeyServicesRight>
                            <Col lg="4" md="12" sm="12">
                                <GatsbyImage
                                    image={JSONData.interior2Json.KeyServices.KeyServicesLeftImage.childImageSharp.gatsbyImageData}
                                    className="KeyServicesLeftImage"
                                    alt="" />
                            </Col>
                            <Col lg="8" md="12" sm="12">
                                <KeyServicesRightInnerRow>
                                    <Col md="6">
                                        <KeyServicesCol1>
                                            <img src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[0].KeyServicesIcon} alt=""/>
                                            <h4>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[0].KeyServicesHeading}</h4>
                                            <p>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[0].KeyServicesDesc}</p>
                                        </KeyServicesCol1>
                                    </Col>
                                    <Col md="6">
                                        <KeyServicesCol2>
                                            <img src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[1].KeyServicesIcon} alt=""/>
                                            <h4>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[1].KeyServicesHeading}</h4>
                                            <p>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[1].KeyServicesDesc}</p>
                                        </KeyServicesCol2>
                                    </Col>
                                    <Col md="6">
                                        <KeyServicesCol1>
                                            <img src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[2].KeyServicesIcon} alt=""/>
                                            <h4>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[2].KeyServicesHeading}</h4>
                                            <p>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[2].KeyServicesDesc}</p>
                                        </KeyServicesCol1>
                                    </Col>
                                    <Col md="6">
                                        <KeyServicesCol2>
                                            <img src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[3].KeyServicesIcon} alt=""/>
                                            <h4>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[3].KeyServicesHeading}</h4>
                                            <p>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[3].KeyServicesDesc}</p>
                                        </KeyServicesCol2>
                                    </Col>
                                </KeyServicesRightInnerRow>
                            </Col>
                        </KeyServicesRight>
                    </KeyServicesCustomContainer>
                </Fade>
            </Container>
        </KeyServicesSection>
    );
}

export default KeyServices;

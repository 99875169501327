import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,FooterCol,
    FooterUl,FooterLi,FooterMenu,FooterSocial,BottomFooterPara,BottomLink,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,
    BottomFooterWrapper} from './footer.style';


import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";


const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            interior2Json {
                Footer {
                    DelayConstant
                    FooterCol1{
                        FooterLogo
                        FooterPara{
                            Para
                        }
                        FooterSocial{
                            FooterSocialImg
                        }
                    }
                    FooterCol2{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        Items
                        FooterUl{
                            FooterMenu
                            Href
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);

    return (
        <div>
        <FooterSection>
            <Container>
                <Fade Bottom delay={JSONData.interior2Json.Footer.DelayConstant}>
                    <Row>
                        <Col md="3">
                            <FooterCol>
                                <img src={JSONData.interior2Json.Footer.FooterCol1.FooterLogo} alt=""/>
                                {
                                    JSONData.interior2Json.Footer.FooterCol1.FooterPara.map((item,idx) => {
                                    return <p>
                                        {item.Para}
                                        </p>
                                    })
                                }
                                <FooterSocial href="#" aria-label="Social Link">
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Social Link">
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Social Link">
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Social Link">
                                    <InstaIcon/>
                                </FooterSocial>

                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <h4>{JSONData.interior2Json.Footer.FooterCol2.FooterTitle}</h4>
                                {
                                    JSONData.interior2Json.Footer.FooterCol2.FooterPara.map((item,idx) => {
                                        return <p>
                                            {item.Para}
                                        </p>
                                    })
                                }
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <h4>{JSONData.interior2Json.Footer.FooterCol3.FooterTitle}</h4>
                                    <FooterUl>
                                        {
                                            JSONData.interior2Json.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                                return <FooterLi>
                                                    <FooterMenu href="#">
                                                        {item.FooterMenu}
                                                    </FooterMenu>
                                                </FooterLi>
                                            })
                                        }
                                    </FooterUl>
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                            <h4>{JSONData.interior2Json.Footer.FooterCol4.FooterTitle}</h4>
                            <ScrollSpy offset={-59} items={JSONData.interior2Json.Footer.Items} currentClassName="is-current">
                                {
                                    JSONData.interior2Json.Footer.FooterCol4.FooterUl.map((item,idx) => {
                                    return <FooterLi>
                                        <AnchorLink offset={55} href={item.Href}>
                                            {item.FooterMenu}
                                        </AnchorLink>
                                    </FooterLi>
                                    })
                                }
                            </ScrollSpy>
                            </FooterCol>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </FooterSection>

        <BottomFooterWrapper>
            <Container>
                <Row>
                    <Fade bottom delay={JSONData.interior2Json.Footer.DelayConstant}>
                        <Col md="12">
                            <BottomFooterPara>
                                {JSONData.interior2Json.Footer.BottomFooterPara}
                                <BottomLink rel="noreferrer" href={JSONData.interior2Json.Footer.BottomFooterLink} target="_blank">
                                {JSONData.interior2Json.Footer.BottomFooterName}
                                </BottomLink>
                            </BottomFooterPara>
                        </Col>
                    </Fade>
                </Row>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;

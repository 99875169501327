import React,{Component} from 'react';
import {Container,Row,Col} from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import  {PorfolioSlider,GallerySection,GallerySliderCol,
    GalleryInnerContainer,PrevImg,NextImg,NextPrev,ImgButton} from './gallery.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Gallery extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            infinite: true,
            arrows:true,
            slidesToShow: 3,
            rows: 2,
            slidesPerRow: 1,
            speed: 1000,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                    breakpoint: 376,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                  }
            ]
        };

        return (
            <GallerySection id="galleryContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.interior2Json.Gallery.DelayConstant}>
                                <SectionHeading>{this.props.interior2Json.Gallery.SectionHeading}</SectionHeading>
                            </Fade>
                        </Col>
                    </Row>

                </Container>
                <Container>
                    <PorfolioSlider>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                            this.props.interior2Json.Gallery.Slider.map((item,idx) => {
                            return (
                                <Fade bottom delay={(idx+1)*this.props.interior2Json.Gallery.DelayConstant}><GallerySliderCol>
                                        <GalleryInnerContainer>
                                            <GatsbyImage
                                                image={item.GallerySliderImg.childImageSharp.gatsbyImageData}
                                                className="GallerySliderImg"
                                                alt="" />
                                        </GalleryInnerContainer>
                                    </GallerySliderCol>
                                </Fade>
                            );
                            })
                        }
                        </Slider>
                    </PorfolioSlider>
                    <NextPrev>
                        <ImgButton onClick={this.previous} aria-label="Prev Button">
                            <PrevImg src={this.props.interior2Json.Gallery.PrevImg} alt=""/>
                        </ImgButton>
                        <ImgButton onClick={this.next} aria-label="Next Button">
                            <NextImg src={this.props.interior2Json.Gallery.NextImg} alt=""/>
                        </ImgButton>
                    </NextPrev>
                </Container>
            </GallerySection>
        );
    }
}

const GalleryPageSection = () => (
    <StaticQuery
        query={graphql`{
  interior2Json {
    Gallery {
      SectionHeading
      Slider {
        GallerySliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Gallery interior2Json={data.interior2Json}/>
        )}
    />
)
export default GalleryPageSection;
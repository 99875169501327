import React,{Component} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SectionHeadingCustom,TestimonialsSection,TestimonialsContainer,CardOuterWrapper,CardProfileLayout,
    ProfilePicLayout,ProfileNameLayout,ProfileLayoutWrapper,
    SliderWrapper,PrevImg,NextImg,NextPrev} from './testimonials.style';
import Slider from "react-slick";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <TestimonialsSection id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.interior2Json.Testimonials.DelayConstant}>
                                <SectionHeadingCustom>{this.props.interior2Json.Testimonials.TestimonialHeading}</SectionHeadingCustom>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                <Fade bottom delay={1*this.props.interior2Json.Testimonials.DelayConstant}>
                    <TestimonialsContainer>
                        <SliderWrapper>
                            <img src={this.props.interior2Json.Testimonials.QuoteImg} alt=""/>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.interior2Json.Testimonials.CardProfileLayout.map((item,idx) => {
                                    return (
                                        <CardOuterWrapper>
                                            <h5>
                                                {item.TestimonailHeading}
                                            </h5>
                                            <p>
                                                {item.Testimonial}
                                            </p>
                                            <ProfileLayoutWrapper>
                                            <CardProfileLayout>
                                                <ProfilePicLayout>
                                                    <GatsbyImage
                                                        image={item.ProfilePic.childImageSharp.gatsbyImageData}
                                                        className="ProfilePic"
                                                        alt="" />
                                                </ProfilePicLayout>
                                                <ProfileNameLayout>
                                                    <h6>
                                                        {item.ProfileName}
                                                    </h6>
                                                    <p>
                                                        {item.Designation}
                                                    </p>
                                                </ProfileNameLayout>
                                            </CardProfileLayout>
                                            </ProfileLayoutWrapper>
                                        </CardOuterWrapper>
                                    );
                                })
                            }
                            </Slider>
                            <NextPrev>
                                <button onClick={this.previous} aria-label="Prev Button">
                                    <PrevImg src={this.props.interior2Json.Testimonials.PrevImg} alt=""/>
                                </button>
                                <button onClick={this.next} aria-label="Next Button">
                                    <NextImg src={this.props.interior2Json.Testimonials.NextImg} alt=""/>
                                </button>
                            </NextPrev>
                        </SliderWrapper>
                    </TestimonialsContainer>
                </Fade>

            </TestimonialsSection>
        );
    }
}


const TestimonialsPageSection = () => (
    <StaticQuery
        query={graphql`{
  interior2Json {
    Testimonials {
      TestimonialHeading
      CardProfileLayout {
        ProfilePic {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        ProfileName
        Designation
        TestimonailHeading
        Testimonial
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Testimonials interior2Json={data.interior2Json}/>
        )}
    />
  )
  export default TestimonialsPageSection;
